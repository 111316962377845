<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            고객사 제공 통계 페이지
          </h2>
        </b-link>
        <b-card-title class="mb-1">
          제약사 제공 통계 페이지 입니다.
        </b-card-title>
        <b-card-text class="mb-2">
          제공된 제약사ID와 접속코드를 입력하여 접속가능합니다.<br>
          등록된 컨텐츠의 데이터를 확인하세요.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="clientloginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- Id -->
            <b-form-group
              label-for="Id"
              label="제약사 ID"
            >
              <validation-provider
                #default="{ errors }"
                name="Id"
                rules="required"
              >
                <b-form-input
                  id="Id"
                  v-model="clientLoginId"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Id 입력"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Id -->
            <b-form-group
              label-for="Id"
              label="접속코드"
            >
              <validation-provider
                #default="{ errors }"
                name="접속코드"
                rules="required"
              >
                <b-form-input
                  id="Id"
                  v-model="accessCode"
                  :state="errors.length > 0 ? false:null"
                  placeholder="접속코드 입력"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              style="margin-bottom:15px"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, getCurrentInstance } from '@vue/composition-api'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton, BForm, BFormInput, BFormGroup, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'
import { getHomeRouteForLoggedInClient } from '@/auth/utils'

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const toast = useToast()
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs
    const router = instance.proxy.$router

    const status = ref('')
    const accessCode = ref('')
    const clientLoginId = ref('')

    const login = () => {
      refs.clientloginForm.validate().then(success => {
        if (success) {
          axios.post('/cl/client/login', {
            clientLoginId: clientLoginId.value,
            accessCode: accessCode.value,
          })
            .then(rs => {
              const userData = rs.data.clientData

              localStorage.setItem('userData', JSON.stringify(userData))
              // move main dashboard
              router.replace(getHomeRouteForLoggedInClient(userData.role))
                .then(() => {
                  toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome Client`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '로그인 되었습니다.',
                    },
                  })
                })
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '서버 응답 오류로 로그인에 실패하였습니다. 잠시 후에 다시 시도해 주세요.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '로그인 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    }

    return {
      required,
      status,
      accessCode,
      clientLoginId,
      login,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
