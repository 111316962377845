var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c(
              "b-link",
              { staticClass: "brand-logo" },
              [
                _c("vuexy-logo"),
                _c("h2", { staticClass: "brand-text text-primary ml-1" }, [
                  _vm._v(" 고객사 제공 통계 페이지 "),
                ]),
              ],
              1
            ),
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" 제약사 제공 통계 페이지 입니다. "),
            ]),
            _c("b-card-text", { staticClass: "mb-2" }, [
              _vm._v(" 제공된 제약사ID와 접속코드를 입력하여 접속가능합니다."),
              _c("br"),
              _vm._v(" 등록된 컨텐츠의 데이터를 확인하세요. "),
            ]),
            _c("validation-observer", {
              ref: "clientloginForm",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "b-form",
                        {
                          staticClass: "auth-login-form mt-2",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.login($event)
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { "label-for": "Id", label: "제약사 ID" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Id", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "Id",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              placeholder: "Id 입력",
                                              autofocus: "",
                                            },
                                            model: {
                                              value: _vm.clientLoginId,
                                              callback: function ($$v) {
                                                _vm.clientLoginId = $$v
                                              },
                                              expression: "clientLoginId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { "label-for": "Id", label: "접속코드" } },
                            [
                              _c("validation-provider", {
                                attrs: { name: "접속코드", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "Id",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              placeholder: "접속코드 입력",
                                              autofocus: "",
                                            },
                                            model: {
                                              value: _vm.accessCode,
                                              callback: function ($$v) {
                                                _vm.accessCode = $$v
                                              },
                                              expression: "accessCode",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-bottom": "15px" },
                              attrs: {
                                variant: "primary",
                                type: "submit",
                                block: "",
                                disabled: invalid,
                              },
                            },
                            [_vm._v(" Sign in ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }